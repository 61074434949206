import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: true,
    returnNull: false,
    parseMissingKeyHandler: (key) => {
      if (process.env.NODE_ENV === "production") {
        return "";
      }

      return key;
    },
    react: {
      useSuspense: false,
    },
    ns: "common",
    defaultNS: "common",
    backend: {
      loadPath:
        (window.location.origin ?? "") +
        `/locales/{{lng}}/{{ns}}.json${
          import.meta.env.VITE_APP_BUILD_VERSION
            ? `?v=${import.meta.env.VITE_APP_BUILD_VERSION}`
            : ""
        }`,
    },
  })
  .catch((error) => console.error(`i18n.translate error ${error.toString()}`));

export default i18n;
